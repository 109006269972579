<template>
  <v-container fluid>
    <v-row no-gutters align='center' justify='end' class='mt-5'>
      <v-menu
        v-if='!isSuperAdmin'
        bottom
        left
        offset-y
      >
        <template #activator='{ on, attrs }'>
          <v-btn
            icon
            v-bind='attrs'
            v-on='on'
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list class='pa-0'>
          <v-list-item @click='changePasswordDialog = true'>
            <v-list-item-title>{{$t('CHANGE_PASSWORD')}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>

    <v-row no-gutters align='center' justify='center'>
      <v-avatar size='200' color='primary'>
        <v-icon size='60' dark>
          mdi-account
        </v-icon>
      </v-avatar>
    </v-row>

    <v-row no-gutters align='center' justify='center' class='mt-3'>
      <v-card-title class='text-h5 pb-0' style='margin-left: -70px'>
        <v-chip outlined :color='getRole(getUser.account.accountRoleId).color' class='mr-3'>
          {{getRole(getUser.account.accountRoleId).description}}
        </v-chip>
        {{getUser.account.name}}
      </v-card-title>
    </v-row>
    <v-row no-gutters align='center' justify='center'>
      <span class='text-caption'>
        {{getUser.account.email}}
      </span>
    </v-row>

    <v-row no-gutters align='center' justify='center' class='my-3'>
      <v-divider style='max-width: 500px' />
    </v-row>

    <v-row no-gutters align='center' justify='center'>
      <v-tooltip bottom>
        <template #activator='{ on }'>
          <div class='text-caption' v-on='on'>
            {{$t('CREATED_AT', { value: formattedDate(getUser.account.createdAt) })}}
          </div>
        </template>
        {{formattedDateInWords(getUser.account.createdAt)}}
      </v-tooltip>
    </v-row>

    <!-- ---------------------------------------------------------------- -->
    <!-- DIALOGS -->
    <!-- ---------------------------------------------------------------- -->
    <v-dialog v-model='changePasswordDialog' fullscreen persistent>
      <v-card v-if='changePasswordDialog' style='background-color: #e0e0e0;'>
        <v-container fluid class='pa-0'>
          <v-app-bar dark color='primary'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click.native='changePasswordDialog = false'>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{$t('CLOSE')}}</span>
            </v-tooltip>

            <div class='text-h5 ml-2'>
              {{$t('CHANGE_PASSWORD')}}
            </div>
          </v-app-bar>

          <profile-change-password @close='changePasswordDialog = false' />
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { format, parseISO, formatDistanceToNow } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';
  import { ACCOUNT_ROLE } from '@/store/modules/Auth';

  export default {
    name: 'Profile',
    components: {
      ProfileChangePassword: () => import('./ProfileChangePassword'),
    },
    data: function () {
      return {
        changePasswordDialog: false,
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
      isSuperAdmin: function () {
        return this.getUser.account.accountRoleId === ACCOUNT_ROLE.SUPER_ADMIN;
      },
    },
    methods: {
      formattedDate: function (value) {
        return format(parseISO(value), 'dd/MM/yyyy HH:mm');
      },
      formattedDateInWords: function (value) {
        return formatDistanceToNow(parseISO(value), { locale: ptBr });
      },
      getRole: function (roleId) {
        if (roleId === ACCOUNT_ROLE.SUPER_ADMIN) {
          return {
            color: 'error',
            description: this.$t('SUPER_ADMIN'),
          };
        }
        if (roleId === ACCOUNT_ROLE.ADMIN) {
          return {
            color: 'error',
            description: this.$t('ADMIN'),
          };
        }
        return {
          color: 'info',
          description: this.$t('USER'),
        };
      },
    },
  };
</script>
